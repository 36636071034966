import React from 'react';

import {
  OfficeBuildingIcon,
  DeviceMobileIcon,
  CloudIcon,
  BriefcaseIcon
} from '@heroicons/react/outline'


const items = [
  {
    title: 'Market Place',
    description: 'Access and purchase fresh produce from our market place.',
    icon: OfficeBuildingIcon,
    background: 'bg-pink-500',
  },
  {
    title: 'M-SHAMBA Mobile App',
    description: 'Do more with M-SHAMBA app available on playstore.',
    icon: DeviceMobileIcon,
    background: 'bg-yellow-500',
  },
  {
    title: 'Cloud Call Centre',
    description: 'Make and receive calls with our VCC solution. Create call and SMS campaigns. Send Single or Bulk SMS messages.',
    icon: CloudIcon,
    background: 'bg-green-500',
  },
  {
    title: 'USSD Application',
    description: 'Access M-SHAMBA Services via our USSD application from anywhere.',
    icon: DeviceMobileIcon,
    background: 'bg-blue-500',
  },
  {
    title: 'Web Portal',
    description: 'Access our M-SHAMBA services from anywhere over the web.',
    icon: CloudIcon,
    background: 'bg-indigo-500',
  },
  {
    title: 'Extension Services',
    description: 'Request for extension services via our applications and get an instant response',
    icon: BriefcaseIcon,
    background: 'bg-purple-500',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Overview() {
    return (
      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src="./work.jpeg"
              alt=""
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <h2 className="text-lg font-medium text-gray-900 font-extrabold text-3xl">Our Services</h2>
            <p className="mt-1 text-sm text-gray-500">
              We offer an array of services as listed below.
            </p>
            <ul role="list" className="mt-6 border-t border-b border-gray-200 py-6 grid grid-cols-1 gap-6 sm:grid-cols-2">
              {items.map((item, itemIdx) => (
              <li key={itemIdx} className="flow-root">
                <div className="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500">
                  <div
                    className={classNames(
                      item.background,
                      'flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg'
                    )}
                  >
                    <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <div>
                    <h3 className="text-sm font-medium text-gray-900">
                      <a href="#" className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        {item.title}
                      </a>
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                  </div>
                </div>
              </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
  