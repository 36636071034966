import React from 'react';
import {
    DeviceMobileIcon,
    CloudIcon,
    ChatAlt2Icon,
    DeviceTabletIcon,
    ServerIcon,
    DesktopComputerIcon,
  } from '@heroicons/react/outline'
  
  const features = [
    {
        name: 'USSD Applications', 
        icon: DeviceMobileIcon,
        desc: 'We enable all users with any kind of handsets to interact with our first class leading USSD'
    },
    { 
        name: 'SMS & Survey Systems', 
        icon: ChatAlt2Icon,
        desc: 'We enable all users with any kind of handsets to interact with our first class lSMS applications'
    },
    { 
        name: 'Mobile Apps (Android & iOS)', 
        icon: DeviceTabletIcon,
        desc: 'We think Mobile. We build beautiful smart and functional applications for the most widely used smartphones in the world'
    },
    { 
        name: 'Web Applications/Systems', 
        icon: DesktopComputerIcon,
        desc: 'We build scalable web applications that are of international quality and standards'
    },
    { 
        name: 'Backend & API Development', 
        icon: CloudIcon,
        desc: 'We build scalable APIs, we also do software integrations and deploypments for third party consumption services.'
    },
    { 
        name: 'Database & Server Management', 
        icon: ServerIcon,
        desc: 'We develop and manage database management systems and also offer server management services.'
    },
  ]
  
  export default function TechnologyView() {
    return (
      <div className="relative bg-white py-16 sm:py-24 lg:py-32">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <h2 className="text-base font-semibold tracking-wider text-gray-600 uppercase">Our Technology</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            Why <span className="text-yellow-500">Choose Us</span>
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            Our technology is versatile and highly customizable to solve various challenges. The technology we use include:
          </p>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-yellow-500 rounded-md shadow-lg">
                          <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                      <p className="mt-5 text-base text-gray-500">
                        {feature.desc}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
