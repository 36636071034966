import React from 'react';
import Header from './header/Header';
import Highlight from './Highlight';
import Overview from './Overview';
import Footer from './Footer';
import Features from './Features';
import Metrics from './Metrics';

function Home() {
    return (
      <div className="App">
        <Header />
        <Highlight />
        <Features />
        <Metrics />
        <Overview />
        <Footer />
      </div>
    );
  }
  
  export default Home;