import React from 'react';

export default function Highlight() {
    return (
      <div className="bg-green-700">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white">What is M-SHAMBA</h2>
          <div className="flow-root mt-8 lg:mt-10">
            <p className="mt-3 text-lg text-white">M-shamba digital platform supports digital learning on agronomy, climate smart interventions and food safety to the farmers through the Interactive Voice Response (IVR) service and interactive SMS. The platform also connects the farmers to the experts in the field through the Cloud Based Virtual Call Centre for real time and personalized support.</p>
          </div>
        </div>
      </div>
    )
  }
  