import React from 'react';

import HeaderNoNav from './header/HeaderNoNav';
import Footer from './Footer';
import ImpactView from '../views/Impact';

function OurImpact() {
    return (
      <div className="App">
        <HeaderNoNav />
        <ImpactView />
        <Footer />
      </div>
    );
  }
  
  export default OurImpact;