import React from 'react';

import HeaderNoNav from './header/HeaderNoNav';
import Footer from './Footer';
import ArticlesView from '../views/Articles';

function Articles() {
    return (
      <div className="App">
        <HeaderNoNav />
        <ArticlesView />
        <Footer />
      </div>
    );
  }
  
  export default Articles;