import React from 'react';

import HeaderNoNav from './header/HeaderNoNav';
import Footer from './Footer';
import ContactView from '../views/Contact';

function Contact() {
    return (
      <div className="App">
        <HeaderNoNav />
        <ContactView />
        <Footer />
      </div>
    );
  }
  
  export default Contact;