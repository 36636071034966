import React from 'react';

import HeaderNoNav from './header/HeaderNoNav';
import Footer from './Footer';
import TechnologyView from '../views/Technology';

function Technology() {
    return (
      <div className="App">
        <HeaderNoNav />
        <TechnologyView />
        <Footer />
      </div>
    );
  }
  
  export default Technology;