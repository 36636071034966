import React from 'react';

import HeaderNoNav from './header/HeaderNoNav';
import Footer from './Footer';
import CareersView from '../views/Careers';

function Careers() {
    return (
      <div className="App">
        <HeaderNoNav />
        <CareersView />
        <Footer />
      </div>
    );
  }
  
  export default Careers;