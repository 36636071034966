import React from 'react';

export default function CareersView() {
    return (
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-green-700 sm:text-4xl">
                Front End Developer
              </span>
              <br/>
              <span class="block text-base text-center text-yellow-600 font-semibold tracking-wide uppercase">Job Description</span>
            </h1>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Oversee UI and UX design for mobile and web applications. Articulate the design in the form of wireframes for client and/or internal development team approval. Undertake development tasks necessary to translate design to templates (HTML) for use by Software Developers. Refactor components for reuse across applications and platforms. Write any scripting necessary to ensure UI components work well. Understand, translate and incorporate client and user specifications into design and final products. Undertake reviews of existing code for inefficiencies and improve upon existing code.<br/>
              Develop new features in products and solutions as per specification. On occasion, design graphics and UI mockups as needed by the company. The successful candidate must be intellectually curious with the ability pick up concepts quickly in a fast paced environment. The role also provides an exceptional opportunity for learning new and cutting edge technologies across IoT, mobile and web channels as well as career growth and the experience of working in a challenging start-up environment.
            </p>

            <h1>
              <br/>
              <span class="block text-base text-center text-yellow-600 font-semibold tracking-wide uppercase">Qualifications</span>
            </h1>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              A Bachelor's Degree or Diploma in Computer Science, Information Science or Web Development and Design. Minimum 1 year work experience-freelance or employment Show case past work and competency in the relevant skill sets Strong creative design and wire framing skills (using tools like Freehand and Figma).<br/>
              Strong understanding of HTML5, CSS3, JavaScript and issues relating to browser compatibility Knowledge of frameworks such as Material UI, Semantic or Ant Design. Strong image editing software skills (Photoshop, Illustrator and InDesign). Understanding of and comfortable using development tools such as code repositories and IDEs.<br/>
              Familiarity with the Agile Methodology for software development Good written and verbal communication skills, self-starter with initiative requiring minimal supervision. A quick learner, innovation and critical thinker.
            </p>

            <h1>
              <br/>
              <span class="block text-base text-center text-yellow-600 font-semibold tracking-wide uppercase">How to Apply</span>
            </h1>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Send application to info@m-shamba.net
            </p>
          </div>
        </div>
      </div>
    )
  }
  