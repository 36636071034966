import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Suspense } from 'react';

import Articles from './components/Articles';
import Careers from './components/Careers';
import Contact from './components/Contact';
import Home from './components/Home';
import Impact from './components/Impact';
import Technology from './components/Technology';

import ReactLoader from './components/Loader';

function App() {
  return (
    <Router>
      <Suspense fallback={<ReactLoader />}>
        <Switch>
          <Route path="/articles" component={Articles} exact />
          <Route path="/careers" component={Careers} exact />
          <Route path="/contact" component={Contact} exact />
          <Route path="/" component={Home} exact />
          <Route path="/impact" component={Impact} exact />
          <Route path="/technology" component={Technology} exact />
          
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
