import React from 'react';

import { MailIcon, PhoneIcon } from '@heroicons/react/outline'

export default function ContactView() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
          <div>
            <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Kisumu Office</h2>
            <div className="mt-3">
              <p className="text-lg text-gray-500">
                M-shamba Centre, Kibos Road
              </p>
            </div>
            <div className="mt-9">
              <div className="flex">
                <div className="flex-shrink-0">
                  <PhoneIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <p>+254 725 244 465</p>
                  <p className="mt-1">Mon-Fri 8am to 5pm EAT</p>
                </div>
              </div>
              <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <MailIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <p>info@m-shamba.net</p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 md:mt-0">
            <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Nairobi Office</h2>
            <div className="mt-3">
              <p className="text-lg text-gray-500">
                Sifa Towers, 5th floor, Lenana Road
              </p>
            </div>
            <div className="mt-9">
              <div className="flex">
                <div className="flex-shrink-0">
                  <PhoneIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <p>+254 735 135 435</p>
                  <p className="mt-1">Mon-Fri 8am to 5pm EAT</p>
                </div>
              </div>
              <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <MailIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                <div className="ml-3 text-base text-gray-500">
                  <p>info@m-shamba.net</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
