import React from 'react';

export default function Metrics() {
    return (
      <div className="bg-yellow-500">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">Our Metrics</h2>
          </div>
          <dl className="mt-10 text-center sm:max-w-7xl sm:mx-auto sm:grid sm:grid-cols-4 sm:gap-8">
            <div className="flex flex-col">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-white">Farmers Reached</dt>
              <dd className="order-1 text-5xl font-extrabold text-white">68,546</dd>
            </div>
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-white">Acres of Land Covered</dt>
              <dd className="order-1 text-5xl font-extrabold text-white">400,040</dd>
            </div>
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-white">Worth of Comodities Traded</dt>
              <dd className="order-1 text-5xl font-extrabold text-white">KSH 12M</dd>
            </div>
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-white">Farmers Cooperatives Worked With</dt>
              <dd className="order-1 text-5xl font-extrabold text-white">90</dd>
            </div>
          </dl>
        </div>
      </div>
    )
  }
  